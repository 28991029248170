import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './assets/css/general.css';
import AppRoutes from './App.routes';
import { Helmet } from 'react-helmet';

const App: React.FC = () => {

  const clarityScript = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "nysar8gsqx");`;
  
  return (
    <div className="">
      <Helmet>
        <script type="text/javascript" >
          {clarityScript}
        </script>
      </Helmet>
      <AppRoutes />
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default App;
